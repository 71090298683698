@media screen and (min-width: 820px){
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

@media screen and (max-width: 820px){
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}


::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.5);
  border: 31px none #ffffff;
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.7);
}


::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.7);;
}


::-webkit-scrollbar-track {
  background: #f0f0f0;
  border: 1px solid #ffffff;
  border-radius: 5px;
}


::-webkit-scrollbar-track:hover {
  background: #f2f2f2;
}


::-webkit-scrollbar-track:active {
  background: #f5f5f5;
}


::-webkit-scrollbar-corner {
  background: transparent;
}

/*Fin de la personnalisation */